/* used to wrap and concat css files */

/* need to specify parentId through build command */
/*@parentId: ~'#leaf-writer-container';*/

@{parentId} {
  @import (less) url('../js/layout/layout.css');
  @import (less) url('./style.css');
  @import (less) url('./jquery-ui/jquery-ui.css');
  @import (less) url('./jquery.layout_and_plugins.css');

  @import (less) url('./icons.less');

  @import (less) url('../js/dialogs/attributeWidget/attributeWidget.css');
  @import (less) url('../js/dialogs/dialogForm/dialogForm.css');
  @import (less) url('../js/dialogs/loadingIndicator/loadingIndicator.css');

  @import (less) url('../js/layout/panels/entitiesList/entitiesList.less');
  @import (less) url('../js/layout/panels/imageViewer/imageViewer.css');
  @import (less) url('../js/layout/panels/validation/validation.css');

  @import (less) url('./jquery-ui-overrides.css');
  @import (less) url('./jquery-layout-overrides.css');
}

/* can't wrap these because otherwise they're TOO specific */
@import (less) url('./tinymce-overrides.css');
