@import (less) 'variables.less';

.fa,
.fas {
  font-family: 'Font Awesome 5 Free' !important;
  font-weight: 900 !important;
  color: #777;
}

/* entities */

.fas.person:before {
  content: '\f007';
  color: @entity-person;
}

.fas.organization:before {
  content: '\f0c0';
  color: @entity-organization;
}

.fas.place:before {
  content: '\f0ac';
  color: @entity-place;
}

.fas.date:before {
  content: '\f073';
  color: @entity-date;
}

.fas.citation:before {
  content: '\f2bb';
  color: @entity-citation;
}

.fas.note:before {
  content: '\f249';
  color: @entity-note;
}

.fas.work:before {
  content: '\f02d';
  color: @entity-work;
}

.fas.correction:before {
  content: '\f06a';
  color: @entity-correction;
}

.fas.keyword:before {
  content: '\f084';
  color: @entity-keyword;
}

.fas.link:before {
  content: '\f0c1';
  color: @entity-link;
}

.fas.thing:before {
  content: '\f49e';
  color: @entity-thing;
}

/* general purpose */

.fas.tag:before {
  content: '\f02b';
  color: #8fcafb;
}

.fas.tag-edit:before {
  content: '\f02b';
  color: #dfb667;
}

.fas.tag-delete:before {
  content: '\f02b';
  color: #e36e20;
}

.fas.tag-copy:before {
  content: '\f02c';
}

.fas.toggle-tags:before {
  content: '\f121';
  color: #63a0e0;
}

.fas.view-markup:before {
  content: '\f1c9';
  color: #63a0e0;
}

.fas.edit-source:before {
  content: '\f044';
}

.fas.add-triple:before {
  content: '\f542';
}

.fas.help:before {
  content: '\f059';
}

.fas.settings:before {
  content: '\f013';
}

.fas.save:before {
  content: '\f0c7';
}

.fas.save-exit:before {
  content: url(../icons/svg/save-exit.svg);
}

.fas.load:before {
  content: '\f07c';
}

.fas.exit:before {
  content: '\f2f5';
}

.fas.validate:before {
  content: '\f46c';
}

.fas.fullscreen-activate:before {
  content: '\f31e';
}

.fas.fullscreen-deactivate:before {
  content: '\f78c';
}

.fas.translation:before {
  content: '\f1ab';
}
